import { Button, Modal, Row, Skeleton, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { AgentInfo } from '../../pages/agents/agentsOverview/overview';
import { AgentService } from '../../services/agent.service';
import type { ColumnsType } from 'antd/es/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import Table from 'antd/es/table';
import { getNameString } from '../../utils/name.utils';
import { useAuth } from '../../auth/authProvider';

interface AgentsByStateGroupType {
  isViewAgents: boolean;
  stateGroupId: string;
  isEditMode?: boolean;
  close: () => void;
}

const AgentsByStateGroupModal = ({
  isViewAgents,
  close,
  stateGroupId,
  isEditMode,
}: AgentsByStateGroupType) => {
  const { getAccessTokenSilently } = useAuth();
  const [agents, setAgents] = useState<AgentInfo[]>([]);
  const [isAgentLoading, setIsAgentLoading] = useState(false);

  const fetchAgentForStateGroup = async () => {
    setIsAgentLoading(true);
    const token = await getAccessTokenSilently();
    const response = await AgentService.getAgentsforStateGroup(
      stateGroupId,
      token
    )
      .catch((error) => {
        console.error('error :: ', error);
      })
      .finally(() => {
        setIsAgentLoading(false);
      });
    setAgents(response?.data as AgentInfo[]);
  };

  useEffect(() => {
    fetchAgentForStateGroup();
  }, []);

  const AgentColumns: ColumnsType<AgentInfo> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: AgentInfo) => (
        <Row align="middle">
          <div>
            <Typography.Paragraph
              style={{
                color: 'var(--secondary-color)',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {getNameString(text)}
            </Typography.Paragraph>
          </div>
        </Row>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      render: (text: string) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 400,
            }}
          >
            {text}
          </Typography.Paragraph>
        </Row>
      ),
    },
  ];

  return (
    <Modal
      open={isViewAgents}
      onCancel={() => close()}
      closable
      centered
      title="Producers present in the state group"
      destroyOnClose
      width={690}
      style={{ boxShadow: 'none' }}
      footer={
        <div>
          <div>
            <Button
              htmlType="submit"
              size="large"
              className="button"
              disabled={isAgentLoading}
              style={{
                marginBottom: '10px',
              }}
              onClick={() => {
                close();
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      }
    >
      <Typography.Text
        type="secondary"
        style={{ fontSize: '14px', marginBottom: 6 }}
      >
        <Space>
          <InfoCircleOutlined />
          <p>
            The assignments of the following producers will be affected by
            {isEditMode ? ' editing' : ' deleting'} the territory
          </p>
        </Space>
        {isAgentLoading ? (
          <Skeleton />
        ) : (
          <div>
            <Table
              columns={AgentColumns}
              dataSource={agents}
              scroll={{ y: 400 }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </div>
        )}
      </Typography.Text>
    </Modal>
  );
};

export default AgentsByStateGroupModal;
