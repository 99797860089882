import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd';
import { InfoCircleOutlined, SelectOutlined } from '@ant-design/icons';
import {
  MultipleOnboardLookup,
  MultipleOnboardSteps,
} from '../../../enums/onboardSteps.enum';
import {
  licenseStateColumns,
  nameNpnColumns,
  ssnColumns,
} from '../../../utils/table-manager/lookupTemplate.table-manager';
import {
  licenseStateData,
  nameNpnData,
  ssnData,
} from '../../../data/mockData/onboardLookupTemplate.mockData';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AgentService } from '../../../services/agent.service';
import CustomSteps from '../../../components/common/steps/customStepper';
import LookupTemplatePopup from '../../../components/common/lookupTemplatePopup/lookupTemplatePopup';
import MultipleOnboardAssignments from './multipleOnboard/multipleOnboardingAssignments';
import MultipleOnboardingPaymentConfig from './multipleOnboard/multipleOnboardingPaymentConfig';
import OnboardDraft from './draft/draft';
import { PayeeEnum } from '../../../enums/payee.enum';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import ProducerView from './multipleOnboard/producerView';
import RadioGroup from '../../../components/common/cardSelect/cardSelect';
import React from 'react';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import TextWithIcon from '../../../components/common/textWithIcon/textWithIcon';
import googleSheetsLogo from '../../../assets/icons/googleSheetsLogo.svg';
import { multipleOnboardStore } from '../../../stores/multipleOnboardStore';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

interface MultipleOnboardProps {
  isBlocking: boolean;
  openWarningModal: boolean;
  changeTab: (state: boolean) => void;
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
}

function MultipleOnboard({
  isBlocking,
  setIsBlocking,
  openWarningModal,
  changeTab,
}: MultipleOnboardProps) {
  const [fileName, setFileName] = useState<string>('Onboard_SSN_NPN.csv');
  const [showTemplatePopup, setShowTemplatePopup] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>();
  const { getAccessTokenSilently } = useAuth();
  const [fileLink, setFileLink] = useState<string>(
    `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Onboard_SSN_NPN.csv`
  );
  const [loader, setLoading] = useState<boolean>(false);
  const blocker = unstable_useBlocker(isBlocking);
  const [leaving, setLeaving] = useState(false);
  const [warningModalVisibility, setWarningModalVisibility] =
    useState<boolean>(false);
  const [assignedStates, setAssignedStates] = useState<any[]>([]);
  const [groupedData, setGroupedData] = useState<any[]>([]);

  const navigate = useNavigate();
  const downloadLink = 'exampleSheet.csv';
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['info']({
      message: 'Onboarding is in progress',
      description: message,
    });
  };

  // const infoOptions = [
  //   {
  //     key: 'ssn',
  //     title: 'Individual SSN and Last Name',
  //     desc: 'These above columns should be in your file',
  //     value: MultipleOnboardLookup.SSN,
  //     fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/SSN_LastName.csv`,
  //   },
  //   // {
  //   //   key: 'npn',
  //   //   title: 'Name and NPN Number',
  //   //   desc: 'These above columns should be in your file',
  //   //   value: MultipleOnboardLookup.NPN,
  //   //   fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/Name_NPN.csv`,
  //   // },

  //   // {
  //   //   key: 'state',
  //   //   title: 'Name, License Number and State',
  //   //   desc: 'These above columns should be in your file',
  //   //   value: MultipleOnboardLookup.LICENSE,
  //   //   fileLink: `${process.env.REACT_APP_CLOUD_FRONT_URL}/templates/License_State.csv`,
  //   // },
  // ];

  const stepInfo = [
    {
      steps: 'CSV Upload',
      heading: '',
      content: '',
      key: 0,
    },
    {
      steps: 'Producers View',
      heading: 'Producers View',
      content:
        'All producers in the CSV File are mapped with our system fields and verified are shown here',
      key: 1,
    },
    {
      steps: 'Assignment',
      heading: 'Assign State or Territories',
      content:
        'Assign States or Territories to this producer" to "Assign States or Territories to this batch of prodcuers',
      key: 2,
    },
    {
      steps: 'Configure Payments',
      heading: 'Manage Payment Configs',
      content:
        'Configure who will pay for new licenses and renewals for all the producers',
      key: 3,
    },
    // {
    //   steps: 'Needs Attention',
    //   heading: 'Needs Attention',
    //   content: 'Take action on inactive licenses or missing LOAs',
    //   key: 4,
    // },
  ];

  const steps = stepInfo.map((info) => info.steps);

  const handleSkip = () => {
    if (multipleOnboardStore.currentStep === MultipleOnboardSteps.ASSIGNMENT) {
      multipleOnboardStore.setAssignmentSkipped(true);
    }

    multipleOnboardStore.setCurrentStep(multipleOnboardStore.currentStep + 1);
    multipleOnboardStore.setMaxStep(multipleOnboardStore.maxStep + 1);
  };

  useEffect(() => {
    if (
      multipleOnboardStore.currentStep === MultipleOnboardSteps.AGENT_VIEW &&
      leaving
    )
      setWarningModalVisibility(true);
    if (!isBlocking && leaving) {
      navigate(`${RouteConstants.allAgents.path}`);
    }
  }, [leaving, isBlocking]);

  useEffect(() => {
    return () => {
      multipleOnboardStore.resetMultipleOnboard();
    };
  }, []);

  const handleFinish = () => {
    if (
      multipleOnboardStore.currentStep === MultipleOnboardSteps.PAYMENT_CONFIG
    ) {
      setIsBlocking(false);
      setLeaving(true);
    }
  };

  const handleOnboard = async () => {
    setLoading(true);
    setIsBlocking(true);
    try {
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.multipleOnboard(
        token,
        multipleOnboardStore.bulkOnboardId
      );
      multipleOnboardStore.setCurrentStep(0);
      showError('Please try refreshing again in a while');
      multipleOnboardStore.setBulkAgentsIds(response?.producerIds as string[]);
    } catch (error) {
      setIsBlocking(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const handleNext = () => {
    if (multipleOnboardStore.currentStep === MultipleOnboardSteps.ASSIGNMENT) {
      multipleOnboardStore.setAssignmentSkipped(false);
    }

    multipleOnboardStore.setCurrentStep(multipleOnboardStore.currentStep + 1);
    multipleOnboardStore.setMaxStep(multipleOnboardStore.maxStep + 1);
  };
  const handlePrev = () => {
    if (multipleOnboardStore.currentStep === MultipleOnboardSteps.AGENT_VIEW) {
      setWarningModalVisibility(true);
    } else {
      if (
        multipleOnboardStore.currentStep === MultipleOnboardSteps.IMPORT_AGENTS
      ) {
        multipleOnboardStore.setStartedOnboarded(false);
      } else {
        multipleOnboardStore.setCurrentStep(
          multipleOnboardStore.currentStep - 1
        );
      }
    }
  };

  const handleStepClick = (step: number) => {
    if (multipleOnboardStore.currentStep <= multipleOnboardStore.maxStep) {
      multipleOnboardStore.setCurrentStep(step);
    }
  };

  const [data, setData] = useState<any>(ssnColumns);

  useEffect(() => {
    if (`${process.env.REACT_APP_CLOUD_FRONT_URL}`)
      if (
        multipleOnboardStore.lookupMethod === MultipleOnboardLookup.SSN &&
        process.env.REACT_APP_SSN_TEMPLATE_PATH
      ) {
        setColumns(ssnColumns);
        setData(ssnData);
        setFileName('SSN-LastName.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_SSN_TEMPLATE_PATH
        );
      } else if (
        multipleOnboardStore.lookupMethod === MultipleOnboardLookup.NPN &&
        process.env.REACT_APP_NPN_TEMPLATE_PATH
      ) {
        setColumns(nameNpnColumns);
        setData(nameNpnData);
        setFileName('Name-NPN.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_NPN_TEMPLATE_PATH
        );
      } else {
        setColumns(licenseStateColumns);
        setData(licenseStateData);
        setFileName('License-State.csv');
        setFileLink(
          `${process.env.REACT_APP_CLOUD_FRONT_URL}` +
            process.env.REACT_APP_LICENSE_TEMPLATE_PATH
        );
      }
  }, [multipleOnboardStore.lookupMethod]);

  const validateSubmit = () => {
    const { initialPaymentPayee, renewalPaymentPayee, paymentType, amountCap } =
      multipleOnboardStore.paymentConfig;
    if (!initialPaymentPayee || !renewalPaymentPayee) {
      return false;
    }
    if (paymentType === PaymentTypeEnum.CAP && (!amountCap || amountCap <= 0)) {
      return false;
    }
    if (
      (initialPaymentPayee === PayeeEnum.AGENCY ||
        renewalPaymentPayee === PayeeEnum.AGENCY) &&
      (!paymentType || paymentType === PaymentTypeEnum.NA)
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      {contextHolder}
      <div>
        {!multipleOnboardStore.startedOnboard && (
          <div>
            <Card>
              <Typography.Paragraph
                style={{
                  fontSize: '20px',
                  color: 'var(--primary-color)',
                  fontWeight: 500,
                  marginTop: '10px',
                }}
              >
                Basic Requirements
              </Typography.Paragraph>
              <Typography.Paragraph
                style={{
                  fontSize: '24px',
                  fontWeight: 500,
                  marginTop: '-10px',
                }}
              >
                What information do you have about agents?
                <span style={{ color: '#F00' }}>*</span>
              </Typography.Paragraph>

              <Row justify="space-between">
                {/* <RadioGroup
                options={infoOptions}
                selectedCard={multipleOnboardStore.lookupMethod}
                onOptionChange={(cardValue: MultipleOnboardLookup) => {
                  multipleOnboardStore.setLookupMethod(cardValue);
                }}
              /> */}
              </Row>
              <Typography.Paragraph
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginTop: '10px',
                  color: '#525252',
                }}
              >
                Keep all your agent data to be onboarded ready in a CSV File
              </Typography.Paragraph>
              <Card style={{ backgroundColor: '#F5F5F7', marginTop: '16px' }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Row align="middle">
                      <img src={googleSheetsLogo} alt="logo" />
                      <Typography.Paragraph
                        style={{
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '15px',
                        }}
                      >
                        {fileName} + 1
                      </Typography.Paragraph>
                    </Row>
                    <Typography.Paragraph
                      style={{ fontSize: '12px', color: '#ABABAB' }}
                    >
                      Download this attached example file and use it as a
                      reference <br /> to avoid error while matching column
                      attributes
                    </Typography.Paragraph>
                  </Col>
                  <Col>
                    <Row justify="end">
                      <Button
                        size="large"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          setShowTemplatePopup(true);
                        }}
                      >
                        <SelectOutlined /> Show Template
                      </Button>
                      <Button href={fileLink} size="large">
                        Download
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Card>
            <Button
              size="large"
              style={{ width: '100%', marginTop: '10px' }}
              className="button"
              onClick={() => {
                multipleOnboardStore.setStartedOnboarded(true);
              }}
            >
              Start Onboarding
            </Button>
          </div>
        )}
        {multipleOnboardStore.startedOnboard && (
          <div>
            <Card
              style={{
                padding: '0',
                borderRadius: '6px 6px 0px 0px',
              }}
            >
              <CustomSteps
                steps={steps}
                current={multipleOnboardStore.currentStep}
                onClickStep={handleStepClick}
                maxStep={multipleOnboardStore.maxStep}
                minStep={multipleOnboardStore.minStep}
                disabled={
                  multipleOnboardStore.currentStep ===
                    MultipleOnboardSteps.AGENT_VIEW && loader
                }
              />
            </Card>
            <Card
              style={{
                backgroundColor: '#FCFCFC',
                width: '100%',
                padding: '0',
                borderRadius: '0 0 8px 8px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {multipleOnboardStore.currentStep !==
                  MultipleOnboardSteps.IMPORT_AGENTS &&
                  multipleOnboardStore.currentStep !==
                    MultipleOnboardSteps.ASSIGNMENT && (
                    <div>
                      <Typography.Paragraph
                        style={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'var(--secondary-color)',
                        }}
                      >
                        {stepInfo[multipleOnboardStore.currentStep].heading}
                      </Typography.Paragraph>
                      <Typography.Paragraph
                        style={{
                          marginTop: '-15px',
                        }}
                        className="reset-icon-size"
                      >
                        <TextWithIcon
                          iconColor="#97BFBF"
                          textStyle={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#97BFBF',
                          }}
                          text={
                            stepInfo[multipleOnboardStore.currentStep].content
                          }
                          iconAlignment="left"
                          icon={<InfoCircleOutlined />}
                        />
                      </Typography.Paragraph>
                      {/* {multipleOnboardStore.currentStep ===
                      MultipleOnboardSteps.ACTIONABLE_LICENSES && (
                      <Typography.Paragraph
                        style={{
                          marginTop: '-15px',
                        }}
                      >
                        <TextWithIcon
                          iconColor="#97BFBF"
                          textStyle={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#97BFBF',
                          }}
                          text="Creating licenses for bulk-assigned producers may take time. Please wait to reflect ongoing changes"
                          iconAlignment="left"
                          icon={<InfoCircleOutlined />}
                        />
                      </Typography.Paragraph>
                    )} */}
                    </div>
                  )}
              </div>
              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.IMPORT_AGENTS &&
                fileLink && <OnboardDraft handleNext={handleNext} />}

              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.PAYMENT_CONFIG && (
                <MultipleOnboardingPaymentConfig />
              )}

              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.AGENT_VIEW && (
                <ProducerView setIsBlocking={setIsBlocking} />
              )}

              {multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.ASSIGNMENT && (
                <MultipleOnboardAssignments
                  {...{
                    assignedStates,
                    setAssignedStates,
                    groupedData,
                    setGroupedData,
                  }}
                />
              )}

              {/* {multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.ACTIONABLE_LICENSES && <ActionableLicense />} */}
            </Card>
            {
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '30px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  {multipleOnboardStore.currentStep !==
                    MultipleOnboardSteps.IMPORT_AGENTS &&
                    multipleOnboardStore.currentStep !==
                      MultipleOnboardSteps.ASSIGNMENT && (
                      <Button
                        className="plain-button"
                        onClick={handlePrev}
                        size="large"
                        style={{ width: '120px', marginRight: '10px' }}
                        disabled={loader}
                      >
                        Previous
                      </Button>
                    )}
                </div>

                <div style={{ display: 'flex' }}>
                  {multipleOnboardStore.currentStep ===
                    MultipleOnboardSteps.ASSIGNMENT && (
                    <Button
                      className="plain-button"
                      onClick={handleSkip}
                      size="large"
                      style={{ width: '120px', marginRight: '10px' }}
                    >
                      Skip
                    </Button>
                  )}
                  {multipleOnboardStore.currentStep !==
                    MultipleOnboardSteps.IMPORT_AGENTS && (
                    <Button
                      className="button"
                      onClick={
                        multipleOnboardStore.currentStep ===
                        MultipleOnboardSteps.PAYMENT_CONFIG
                          ? handleFinish
                          : multipleOnboardStore.currentStep ===
                            MultipleOnboardSteps.AGENT_VIEW
                          ? handleOnboard
                          : handleNext
                      }
                      disabled={
                        multipleOnboardStore.currentStep ===
                          MultipleOnboardSteps.PAYMENT_CONFIG &&
                        !validateSubmit()
                      }
                      loading={loader}
                      size="large"
                    >
                      {multipleOnboardStore.currentStep ===
                      MultipleOnboardSteps.PAYMENT_CONFIG
                        ? 'Finish'
                        : multipleOnboardStore.currentStep ===
                          MultipleOnboardSteps.AGENT_VIEW
                        ? 'Onboard Producers'
                        : 'Next'}
                    </Button>
                  )}
                </div>
              </div>
            }
          </div>
        )}

        <Modal
          title={'Are you sure you want to exit the onboarding process?'}
          open={
            blocker.state === 'blocked' ||
            openWarningModal ||
            warningModalVisibility
          }
          maskClosable={false}
          closable={false}
          width={600}
          onOk={() => {
            if (
              multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.AGENT_VIEW
            )
              setWarningModalVisibility(false);
            if (openWarningModal) changeTab(false);
            else blocker.reset?.();
          }}
          onCancel={() => {
            setIsBlocking(false);
            if (openWarningModal) changeTab(true);
            else blocker.proceed?.();
            if (
              multipleOnboardStore.currentStep ===
              MultipleOnboardSteps.AGENT_VIEW
            ) {
              setWarningModalVisibility(false);
              multipleOnboardStore.setCurrentStep(
                multipleOnboardStore.currentStep - 1
              );
            }
          }}
          okText={
            multipleOnboardStore.currentStep === 1
              ? 'Continue Onboarding'
              : 'Complete onboarding'
          }
          cancelButtonProps={{
            className: 'leave-onboard important-action-button',
            disabled:
              multipleOnboardStore.currentStep ===
                MultipleOnboardSteps.AGENT_VIEW && loader,
          }}
          cancelText={
            multipleOnboardStore.currentStep === 1
              ? 'Leave'
              : 'Exit and Complete Later'
          }
        >
          {multipleOnboardStore.currentStep === 1 ? (
            <p>
              Leaving the onboarding process at this stage will discard all the
              information and your onboarding progress will not be saved. Are
              you sure you want to exit?
            </p>
          ) : (
            <p>
              The producers have been onboarded. However, the onboarding process
              involves additional steps that should be completed in their
              respective sections on the platform if you choose to exit. Are you
              sure?
            </p>
          )}
        </Modal>
        <LookupTemplatePopup
          visible={showTemplatePopup}
          setVisible={(visible: boolean) => {
            if (!visible) {
              setShowTemplatePopup(false);
            }
            return showTemplatePopup;
          }}
          lookupTemplateDetails={{
            columns: columns,
            selectedCard: multipleOnboardStore.lookupMethod,
            data: data,
          }}
        />
      </div>
    </>
  );
}

export default withRoles(observer(MultipleOnboard), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
