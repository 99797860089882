import { Row, Tag, Tooltip, Typography } from 'antd';

import { LicenseStatus } from '../../enums/licenseStatus.enum';
import { ResidencyType } from '../../enums/residencyType.enum';
import { capitalize } from 'lodash';
import residentLogo from '../../assets/icons/resident.svg';

interface StateCellProps {
  state: string;
  licenseNumber?: string;
  hidePerpetualTag?: boolean;
  agencyLicenses?: boolean;
  licenseStatus?: string;
  residencyType?: string;
}
function StateCell({
  state,
  licenseNumber,
  hidePerpetualTag,
  agencyLicenses,
  licenseStatus,
  residencyType,
}: StateCellProps) {
  return (
    <Row
      align="middle"
      style={{
        margin: agencyLicenses ? '0px' : '0 5px 0 8px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* {!hidePerpetualTag && (
          <Tooltip title="Perpetual State">
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                border: '1.5px solid blue',
                display: 'flex',
                justifyContent: agencyLicenses ? 'start' : 'center',
                alignItems: 'center',
                marginTop: '3px',
                visibility: getPerpetualStateNames().includes(state)
                  ? 'visible'
                  : 'hidden',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: '10px',
                  color: 'blue',
                  cursor: 'default',
                }}
              >
                P
              </p>
            </div>
          </Tooltip>
        )} */}
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'start',
            }}
          >
            <Tooltip title={state}>
              <Typography.Paragraph
                style={{
                  color: 'var(--secondary-color)',
                  fontSize: '14px !important',
                  fontWeight: 500,
                  marginBottom: licenseNumber ? '1em' : '4px',
                  maxWidth: '100px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                }}
                className="font-14"
                ellipsis
              >
                {state}
              </Typography.Paragraph>
            </Tooltip>
            {agencyLicenses && residencyType === ResidencyType.Resident && (
              <img src={residentLogo} alt="resident" />
            )}
          </div>
          {!agencyLicenses && licenseNumber && (
            <Typography.Paragraph
              style={{
                fontSize: '11px',
                fontWeight: 400,
                marginTop: '-10px',
                marginBottom: '0px',
              }}
            >
              {licenseNumber}
            </Typography.Paragraph>
          )}
          {agencyLicenses && licenseStatus && (
            <Tag
              color={
                licenseStatus.toLocaleLowerCase() === LicenseStatus.Active
                  ? '#98F5C880'
                  : licenseStatus.toLocaleLowerCase() ===
                    LicenseStatus.MISSING_LOA
                  ? '#FCEFCF'
                  : '#FACBCB'
              }
              style={{
                borderRadius: '11.7px',
                marginTop: agencyLicenses ? '0px' : '5px',
                marginRight: agencyLicenses ? '0px' : '5px',
                border: `0.73px solid ${
                  licenseStatus.toLocaleLowerCase() === LicenseStatus.Active
                    ? '#31EB91'
                    : licenseStatus.toLocaleLowerCase() ===
                      LicenseStatus.MISSING_LOA
                    ? '#FFE4A0'
                    : '#EB3131'
                }`,
                padding: `${
                  licenseStatus?.toLowerCase() === LicenseStatus.Active
                    ? `1.46px 4.39px`
                    : `0 4.39px 1.46px 4.39px`
                }`,
                fontSize: '10px',
                fontWeight: '400',
              }}
            >
              <span style={{ color: '#222222', fontFamily: 'Figtree' }}>
                {capitalize(licenseStatus).replace(/_/g, ' ')}
              </span>
            </Tag>
          )}
        </div>
      </div>
    </Row>
  );
}

export default StateCell;
