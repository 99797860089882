import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import CustomIcon from '../common/customiseIcon/customIcon';
import CustomMenu from '../common/customMenu/customMenu';
import { IconConstants } from '../../constants/icons.constants';
import MenuItem from 'antd/es/menu/MenuItem';
import type { MenuProps } from 'antd';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import agencyIcon from '../../assets/icons/agency.svg';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import information from '../../assets/icons/agentInfo.svg';
import { observer } from 'mobx-react-lite';
import { tourStore } from '../../stores/tour.store';
import { withRoles } from '../../auth/useRoles';

// also add in layout.tsx - 133 for showing the sidebar on refresh
export const ADMIN_AGENT_MENU_ROUTES = [
  '/Home',
  '/activity',
  '/appointments',
  '/employment-history',
  '/support-documents',
  '/needs-attention',
  '/background-questions',
  '/all-licenses',
  '/pending-applications',
];

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: string,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    id: `sider-menu-${label.toLowerCase().replace(' ', '-')}`, // changing this will cause issue in the component tour, refer tour.constant.ts file
  } as MenuItem;
}

type menuProp = {
  collapsed: boolean;
  type: 'main' | 'subMenu';
};

function AdminMenu({ collapsed, type }: menuProp) {
  const navigate = useNavigate();
  const [agentId, setAgentId] = useState<string>('');
  const handleNavigate = (path: string): void => {
    navigate(path);
  };

  const adminMenu = useMemo(
    () => [
      getItem(
        'Home',
        RouteConstants.allAgents.key,
        RouteConstants.allAgents.icon,
        undefined,
        () => handleNavigate(RouteConstants.allAgents.path)
      ),
      getItem(
        'Producers',
        'admin-agents',
        <CustomIcon icon={IconConstants.agents} />,
        [
          getItem(
            'Manage Producers',
            RouteConstants.manageAssignments.key,
            RouteConstants.manageAssignments.icon,
            undefined,
            () => handleNavigate(RouteConstants.manageAssignments.path)
          ),
          getItem(
            'Manage Licenses',
            RouteConstants.agencyLicenses.key,
            RouteConstants.agencyLicenses.icon,
            undefined,
            () => handleNavigate(RouteConstants.agencyLicenses.path)
          ),
          getItem(
            'Appointments',
            RouteConstants.agentRTS.key,
            RouteConstants.agentRTS.icon,
            undefined,
            () => handleNavigate(RouteConstants.agentRTS.path)
          ),
          getItem(
            'Pending Applications',
            RouteConstants.agencyAgentApplications.key,
            RouteConstants.agencyAgentApplications.icon,
            undefined,
            () => handleNavigate(RouteConstants.agencyAgentApplications.path)
          ),
        ]
      ),

      getItem('Agency', 'admin-agency', <CustomIcon icon={agencyIcon} />, [
        getItem(
          'Licenses',
          RouteConstants.licensesAgency.key,
          RouteConstants.licensesAgency.icon,
          undefined,
          () => handleNavigate(RouteConstants.licensesAgency.path)
        ),

        getItem(
          'Territories',
          RouteConstants.stateGroups.key,
          RouteConstants.stateGroups.icon,
          undefined,
          () => handleNavigate(RouteConstants.stateGroups.path)
        ),
        getItem(
          'Appointments',
          RouteConstants.agencyRTS.key,
          RouteConstants.agencyRTS.icon,
          undefined,
          () => handleNavigate(RouteConstants.agencyRTS.path)
        ),
        getItem(
          'Pending Applications',
          RouteConstants.agencyApplications.key,
          RouteConstants.agencyApplications.icon,
          undefined,
          () => handleNavigate(RouteConstants.agencyApplications.path)
        ),

        getItem(
          'Organization',
          RouteConstants.agencyHierarchy.key,
          RouteConstants.agencyHierarchy.icon,
          undefined,
          () => handleNavigate(RouteConstants.agencyHierarchy.path)
        ),

        ...(agencyProfileStore.agencyDetails.fein
          ? [
              getItem(
                'Details',
                RouteConstants.agencyGeneralProfile.key,
                RouteConstants.agencyGeneralProfile.icon,
                undefined,
                () => handleNavigate(RouteConstants.agencyGeneralProfile.path)
              ),
            ]
          : []),
      ]),
      getItem(
        'Setup',
        'admin-settings',
        <CustomIcon icon={IconConstants.settings} />,
        [
          getItem(
            'Billing',
            RouteConstants.billing.key,
            RouteConstants.billing.icon,
            undefined,
            () => handleNavigate(RouteConstants.billing.path)
          ),
          getItem(
            'Users',
            RouteConstants.admins.key,
            RouteConstants.admins.icon,
            undefined,
            () => handleNavigate(RouteConstants.admins.path)
          ),
        ]
      ),
      getItem(
        'My Account',
        'account-details',
        <CustomIcon icon={IconConstants.user} />,
        [
          getItem(
            'Account',
            RouteConstants.agencyAdminProfile.key,
            RouteConstants.agencyAdminProfile.icon,
            undefined,
            () => handleNavigate(RouteConstants.agencyAdminProfile.path)
          ),
          getItem(
            'Team Invites',
            RouteConstants.invites.key,
            RouteConstants.invites.icon,
            undefined,
            () => handleNavigate(RouteConstants.invites.path)
          ),
        ]
      ),
    ],
    [agencyProfileStore.agencyDetails.fein]
  );
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    path === '/' && navigate(RouteConstants.allAgents.path);
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const match =
      path.match(/\/agent\/([0-9a-fA-F]{24})\/home/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/activity/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/appointments/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/support-documents/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/employment-history/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/background-questions/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/manage-licenses/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/all-licenses/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/pending-applications/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/licenses/);
    if (match) {
      const agentId = match[1];
      setAgentId(agentId);
    }
    path === '/' && navigate(RouteConstants.allAgents.path);
  }, [
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/home/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/activity/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/appointments/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/suppport-documents/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/employment-history/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/background-questions/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/licenses/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/manage-licenses/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/pending-applications/),
  ]);

  // please update the newly added routes in the ADMIN_AGENT_MENU_ROUTES constant for navigating back to previous section
  const adminAgentMenu: MenuItem[] = [
    getItem(
      'Home',
      RouteConstants.agentOverview.key,
      RouteConstants.agentOverview.icon,
      undefined,
      () =>
        handleNavigate(
          RouteConstants.agentOverview.path.replace(':agentId', agentId)
        )
    ),
    // getItem(
    //   'Activity',
    //   RouteConstants.agentActivity.key,
    //   RouteConstants.agentActivity.icon,
    //   undefined,
    //   () =>
    //     handleNavigate(
    //       RouteConstants.agentActivity.path.replace(':agentId', agentId)
    //     )
    // ),
    getItem(
      'My Producer',
      'agent-licenses',
      <CustomIcon icon={IconConstants.agentLicenses} />,
      [
        getItem(
          'All Licenses',
          RouteConstants.agentOverviewAllLicenses.key,
          RouteConstants.agentOverviewAllLicenses.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agentOverviewAllLicenses.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Pending Applications',
          RouteConstants.agentOverviewPendingApplications.key,
          RouteConstants.agentOverviewPendingApplications.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agentOverviewPendingApplications.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Appointments',
          RouteConstants.RTSViewAgentsFromOverView.key,
          RouteConstants.RTSViewAgentsFromOverView.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.RTSViewAgentsFromOverView.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
      ],
      undefined
    ),

    getItem(
      'Onboarding',
      'agency-information',
      <CustomIcon icon={information} />,
      [
        getItem(
          'Employment History',
          RouteConstants.agencyEmploymentHistory.key,
          RouteConstants.agencyEmploymentHistory.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencyEmploymentHistory.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Background Questions',
          RouteConstants.agencyBackgroundQuestions.key,
          RouteConstants.agencyBackgroundQuestions.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencyBackgroundQuestions.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Supporting Documents',
          RouteConstants.agencySupportDocuments.key,
          RouteConstants.agencySupportDocuments.icon,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencySupportDocuments.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
      ]
    ),
  ];

  const defaultOpenKey = tourStore.sideBarOpen
    ? (type === 'main' ? adminMenu : adminAgentMenu).map(
        (data) => `${data?.key}`
      )
    : [
        type === 'main'
          ? location.pathname === '/agency/licenses'
            ? 'admin-agency'
            : 'admin-agents'
          : '1',
      ];

  return (
    <CustomMenu
      menuKeys={(type === 'main' ? adminMenu : adminAgentMenu).map(
        (itr) => itr?.key || ''
      )}
      items={type === 'main' ? adminMenu : adminAgentMenu}
      defaultOpenKey={defaultOpenKey}
      defaultSelectedKey={[type === 'main' ? 'admin-agents' : '1']}
      // defaultSelectedKey={
      //   type === 'main' ? ['1'] : [RouteConstants.agentOverview.key]
      // }
      style={{
        width: collapsed ? 100 : 260,
        height: 'calc(100vh - 200px)',
        boxSizing: 'border-box',
        overflow: 'auto',
        userSelect: 'none',
      }}
    />
  );
}
AdminMenu.displayName = 'AdminMenu';

export default withRoles(observer(AdminMenu), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
