import { Button, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import {
  EyeOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  applySort,
  getStateNameFromCode,
} from '../../../../../utils/common.utils';

import { ActionableLicenseResponseError } from '../../../../../types/response/actionable-license.type';
import { AgencyLicenseType } from '../../../../../enums/agencyLicenseType.enum';
import { AllLicenseResponse } from '../../../../../types/response/all-license.type';
import { AllLicensesAgencyResponseType } from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import { ApplicationStatusEnum } from '../../../../../enums/applicationStatus.enum';
import { ColumnsType } from 'antd/es/table';
import { ErrorResponse } from '../../../../../types/response/errorResponse.type';
import { ExceptionName } from '../../../../../enums/exceptionName.enum';
import { GatewayTransactionApplyError } from '../../../../../types/nipr/gatewayTransaction.types';
import { GeneralTabType } from '../../../../../enums/generalTabs.enum';
import LicenseModal from '../../../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../../../enums/licenseStatus.enum';
import LicensedProducer from '../../../../../components/agencyLicensing/licensedProducer';
import { LicensesService } from '../../../../../services/licenses.service';
import ListAgentDetails from './listAgentDetails';
import { LoaDetail } from '../../../../../types/data/loaDetail.type';
import { LoaStatus } from '../../../../../enums/loaStatus.enum';
import { PortalType } from '../../../../../enums/portalType.enum';
import { RouteConstants } from '../../../../../constants/routes.constants';
import StateCell from '../../../../../components/licenses/stateCell';
import { StateConstants } from '../../../../../constants/state.constants';
import WarningBanner from './warningBanner';
import { WarningBannerType } from '../../../../../enums/warningBannerType.enum';
import activeLoaIcon from '../../../../../assets/icons/success rectangle.svg';
import { appStateInfoStore } from '../../../../../stores/appStateInfo.store';
import error from '../../../../../assets/icons/error.svg';
import { getAssignedDrlp } from '../../../../../components/agencyLicensing/reviewSection';
import { getStatus } from './agencyMap';
import { groupBy } from 'lodash';
import { listColors } from './allLicensesAgencyTab';
import missingLoaIcon from '../../../../../assets/icons/reject rectangle.svg';
import moment from 'moment';
import { statesWithAffiliation } from '../../../../../components/agencyLicensing/statesWithAffiliationsConst';
import success from '../../../../../assets/icons/success.svg';
import { useAuth } from '../../../../../auth/authProvider';
import { useState } from 'react';

interface AllLicensesAgencyType {
  isFilterVisible: boolean;
  collapseKeys: string | string[];
  allLicenses: AllLicensesAgencyResponseType | null;
  onCollapseChange: (key: string | string[]) => void;
  refetch: (r?: any) => void;
  setApplyLicenseErrorModalVisibility?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setErrors?: React.Dispatch<
    React.SetStateAction<ActionableLicenseResponseError[]>
  >;
  selectedLicenseType?: string;
  setSortValue: (s: any) => void;
  setLicenseCount: React.Dispatch<
    React.SetStateAction<{
      activeCount: number;
      needsAttentionCount: number;
      renewalsCount: number;
    }>
  >;
  sortValue: any;
}

const AllLicensesAgencyList: React.FC<AllLicensesAgencyType> = ({
  isFilterVisible,
  collapseKeys,
  allLicenses,
  onCollapseChange,
  refetch,
  setErrors,
  setApplyLicenseErrorModalVisibility,
  selectedLicenseType,
  setSortValue,
  setLicenseCount,
  sortValue,
}) => {
  const { bearerToken } = useAuth();
  const [isApplying, setIsApplying] = useState('');
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>();

  const applyLicenses = async (licenseId: string, isRenewal?: boolean) => {
    if (!bearerToken) return;
    try {
      if (!allLicenses?.isOwnerDetailsFilled) {
        message.error(
          'Please fill the ownership details to proceed forward with the agency licenses application'
        );
        return;
      }
      setIsApplying(licenseId);
      const resp = await LicensesService.applyLicenseForAgency(
        bearerToken,
        licenseId
      );
      if (resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse) {
        const error =
          resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse?.license;
        const lcRespMessages = Array.isArray(error?.response)
          ? error?.response
              ?.filter((itr: any) => !!itr && itr?.approved !== 'Y')
              ?.flatMap((itr: any) => itr?.messages)
              ?.map((d: any) => d?.comments) || []
          : error?.response?.approved !== 'Y'
            ? error?.response?.map((msg: any) => msg?.comments) || []
            : [];
        const loaRespMessages = Array.isArray(error?.loaCodes)
          ? error?.loaCodes
              ?.flatMap((d: any) => d?.response)
              ?.filter((itr: any) => !!itr)
              ?.flatMap((itr: any) =>
                itr?.approved !== 'Y' ? itr?.messages : []
              )
              ?.map((d: any) => d?.comments) || []
          : error?.loaCodes?.approved !== 'Y'
            ? error?.loaCodes?.response
                ?.filter((itr: any) => !!itr)
                ?.flatMap((itr: any) =>
                  itr?.approved !== 'Y' ? itr?.messages : []
                )
                ?.map((d: any) => d?.comments) || []
            : [];
        const intermediateMessages = Array.isArray(error?.intResponse)
          ? error?.intResponse
              ?.filter((itr: any) => !!itr)
              ?.flatMap((d: any) => d?.messages)
              ?.map((itr: any) => {
                return {
                  actionable: itr.actionRequired,
                  message: itr.comments,
                };
              })
          : error?.intResponse?.messages
            ? error?.intResponse?.messages?.map((itr: any) => {
                return {
                  actionable: itr.actionRequired,
                  message: itr.comments,
                };
              })
            : [];
        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: [] };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };
        setErrors &&
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      } else if (resp?.data?.gatewayTransaction?.niprGatewayErrorResponse) {
        const errors = resp?.data?.gatewayTransaction?.niprGatewayErrorResponse;
        setErrors &&
          setErrors([
            {
              LcErrorDetails: {
                errorMessages: [],
              },
              LoaErrorDetails: {
                errorMessages: [],
              },
              mainErrorDetails: {
                errorMessages: errors?.map((d: any) => d.description) || [],
              },
              intermediateErrorDetails: {
                errorMessages: [],
              },
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      }
      // todo :: error modal integration
      if (resp?.data?.status === 'pending') {
        refetch();
        if (isRenewal)
          setLicenseCount((prev) => ({
            ...prev,
            renewalsCount: prev.renewalsCount - 1,
          }));
        else
          setLicenseCount((prev) => ({
            ...prev,
            needsAttentionCount: prev.needsAttentionCount - 1,
          }));
        message.success('License applied successfully');
      }
    } catch (error: any) {
      console.error(error);
      const e = error?.response?.data as ErrorResponse;
      if (
        e?.error?.exceptionName ===
        ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
      ) {
        const error = e.error.data as GatewayTransactionApplyError;
        const lcRespMessages = error.lcRespMessages
          .filter((itr) => !!itr)
          .map((itr) => itr.comment);
        const loaRespMessages = error.loaRespMessages
          .filter((itr) => !!itr)
          .flatMap((itr) =>
            itr.approved !== 'Y'
              ? itr.respMessages.map((msg) => msg.comment)
              : []
          );
        const mainErrorMessages = error.mainErrorMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => itr.description);
        const intermediateMessages = error.intermediateMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => {
            return { actionable: itr.actionRequired, message: itr.comment };
          });
        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: mainErrorMessages };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };
        setErrors &&
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        setApplyLicenseErrorModalVisibility &&
          setApplyLicenseErrorModalVisibility(true);
      } else
        message.error(
          error?.response?.data?.message ||
            'Failed to apply license.please try again'
        );
    } finally {
      setIsApplying('');
    }
  };

  let columns: ColumnsType<AllLicenseResponse> = [
    {
      title: <div>State</div>,
      dataIndex: 'stateCode',
      sorter: true,
      sortOrder:
        sortValue?.sortField === 'stateCode'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      key: 'stateCode',
      width: 60,
      className: 'agency-license-state-row custom-header-cell',
      render: (text: string, record: AllLicenseResponse) => (
        <StateCell
          state={StateConstants[text]}
          licenseStatus={record.status}
          agencyLicenses={true}
          residencyType={record.residencyType}
          hidePerpetualTag
        />
      ),
    },
    {
      title: <div>DRLP Name</div>,
      dataIndex: 'drlps',
      width: 180,
      className: 'agency-license-table-row custom-header-cell',
      render: (drlps: any, record: any) => {
        const displayedDrlps = drlps?.slice(0, 2);
        const remainingDrlpsCount = drlps?.length - 2;

        return drlps?.length ? (
          <div>
            {displayedDrlps.map((data: any, index: number) => (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography.Paragraph
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: '20px',
                    marginBottom: 0,
                    color: '#0074B0',
                  }}
                >
                  {getAssignedDrlp({ ...data })}
                  {index < displayedDrlps.length - 1 ? ',' : ''}
                  {remainingDrlpsCount > 0 && index === 1 && (
                    <Tooltip
                      title={drlps.slice(2).map((data: any, index: number) => (
                        <div key={index}>{getAssignedDrlp({ ...data })}</div>
                      ))}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        {`, +${remainingDrlpsCount}`}
                      </span>
                    </Tooltip>
                  )}
                </Typography.Paragraph>
              </div>
            ))}
          </div>
        ) : (
          <Typography.Paragraph
            style={{ textAlignLast: 'start', color: '#848484' }}
          >
            No DRLPs reported in NIPR
          </Typography.Paragraph>
        );
      },
    },
    {
      title: <div>License Class & LOA's</div>,
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      width: '40%',
      sorter: true,
      sortOrder:
        sortValue?.sortField === 'licenseConfigurations.licenseClass'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      className: 'cell-with-lines agency-license-state-row center-column',
      render: (loas: any, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center !important',
              height: '60px',
            }}
          >
            <Row style={{ width: '30%', alignContent: 'center' }}>
              <div
                style={{
                  gap: '8px',
                  width: '100%',
                  boxSizing: 'border-box',
                  flexGrow: 1,
                }}
              >
                <Tooltip
                  title={`${appStateInfoStore.getLcNameFromCode(
                    record.licenseConfigurations.licenseClass
                  )} (${record.licenseConfigurations.licenseClassCode})`}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '12px',
                      marginBottom: '0.25em',
                      flexBasis: '25%',
                      flexShrink: 0,
                      paddingRight: '20px',
                      textAlignLast: 'start',
                      fontWeight: 500,
                      color: '#001F45',
                      lineHeight: 'normal',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '90%',
                      cursor: 'pointer',
                    }}
                    className="margin-unset"
                    ellipsis
                  >
                    {`${appStateInfoStore.getLcNameFromCode(
                      record.licenseConfigurations.licenseClass
                    )} (${record.licenseConfigurations.licenseClassCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            </Row>

            <div
              style={{
                position: 'absolute',
                top: 0,
                left: '30%',
                bottom: 0,
                width: '1px',
                backgroundColor: '#d9d9d9',
                height: '100%',
              }}
            />

            <div
              style={{
                width: '70%',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '20px',
                alignSelf: 'center',
              }}
            >
              {record.licenseConfigurations.loaDetails.map(
                (loa: LoaDetail, index: number) => {
                  return (
                    <Space size={[4, 16]} key={index}>
                      {loa.status?.toLocaleLowerCase() ===
                      LoaStatus.ACTIVE.toLocaleLowerCase() ? (
                        <img src={activeLoaIcon} alt="activeLoa" />
                      ) : (
                        <img src={missingLoaIcon} alt="nonActiveLoa" />
                      )}
                      <Typography.Paragraph
                        style={{
                          margin: 0,
                          textAlignLast: 'start',
                          color: '#222222',
                          lineHeight: 'normal',
                        }}
                      >
                        {appStateInfoStore.getLoaNameFromCode(loa.loa)}
                      </Typography.Paragraph>
                    </Space>
                  );
                }
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: 'center' }} className="custom-header-cell">
          BG Questions
        </div>
      ),
      dataIndex: 'bgQuestionsEngineLight',
      key: 'bgQuestionsEngineLight',
      // sorter: true,
      width: 70,
      className: 'agency-license-table-row',
      render: (bgQuestionsEngineLight: boolean, record: any) => {
        return bgQuestionsEngineLight ? (
          <div style={{ textAlignLast: 'center' }}>
            <img alt="logo" src={success} />
          </div>
        ) : (
          <div style={{ textAlignLast: 'center' }}>
            <img alt="logo" src={error} />
          </div>
        );
      },
    },
  ];
  if (selectedLicenseType === AgencyLicenseType.RENEWALS)
    columns.push({
      title: (
        <div style={{ textAlign: 'center' }} className="custom-header-cell">
          Expiration Date
        </div>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      // sorter: true,
      width: 180,
      className: 'agency-license-table-row',
      render: (text: string, record: any) => {
        const dateObj = new Date(record?.expiryDate);
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const year = dateObj.getFullYear();

        const daysToExpire = moment(record.expiryDate).fromNow();
        const formattedString =
          daysToExpire && `(${daysToExpire.replace('in ', '')})`;
        const formattedDate = `${month}/${day}/${year}`;
        return (
          <div style={{ textAlignLast: 'center' }}>
            <Typography.Paragraph>
              {record?.expiryDate && `${formattedDate}`}
            </Typography.Paragraph>
            <Typography.Paragraph>{`${formattedString}`}</Typography.Paragraph>
          </div>
        );
      },
    });
  columns.push({
    title: '',
    dataIndex: 'apply',
    key: 'apply',
    width: '150px',
    className: 'agency-license-table-row',
    render: (text: string, record: any) => {
      return (
        <div
          style={{
            fontFamily: 'Figtree !important',
            fontWeight: '400px !important',
            fontSize: '12px !important',
          }}
        >
          {record.status !== LicenseStatus.Active || record?.isRenewal ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 'fit-content',
                gap: 24,
              }}
            >
              <Tooltip
                title={
                  !record?.drlps?.length
                    ? 'Please assign drlp for this state'
                    : !record.bgQuestionsEngineLight
                      ? 'Please fill background questions before applying for this license.'
                      : !allLicenses?.isOwnerDetailsFilled
                        ? 'Please fill the ownership details to proceed forward with the agency licenses application'
                        : record?.latestApplication?.[0]?.status ===
                            ApplicationStatusEnum.PENDING
                          ? 'A pending application already exists for this license'
                          : ''
                }
              >
                <Button
                  type="primary"
                  id="agency-all-licenses-apply-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (applyLicenses)
                      applyLicenses(record.id, record.isRenewal);
                  }}
                  style={{
                    fontSize: '12px',
                    minWidth: '120px',
                    height: '22px',
                    color: '#fff',
                    fontWeight: 500,
                    minHeight: '22px',
                    display: 'flex',
                    padding: '0px 12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Figtree !important',
                    opacity: !(
                      !!isApplying ||
                      record?.latestApplication?.[0]?.status ===
                        ApplicationStatusEnum.PENDING ||
                      statesWithAffiliation.includes(record?.stateName) ||
                      !record.drlps?.length ||
                      !record.bgQuestionsEngineLight ||
                      !allLicenses?.isOwnerDetailsFilled
                    )
                      ? '100%'
                      : '40%',
                    backgroundColor: '#001836', // Custom background color for enabled state
                  }}
                  disabled={
                    !!isApplying ||
                    record?.latestApplication?.[0]?.status ===
                      ApplicationStatusEnum.PENDING ||
                    statesWithAffiliation.includes(record?.stateName) ||
                    !record.drlps?.length ||
                    !record.bgQuestionsEngineLight ||
                    !allLicenses?.isOwnerDetailsFilled
                  }
                  loading={isApplying === record.id}
                >
                  {/* {record.licenseConfigurations?.some(
                    (config: any) => config.agencyAssignment.isAssigned === true
                  )} */}
                  {record?.isRenewal
                    ? 'Renew'
                    : record?.latestApplication?.[0]?.status === 'declined'
                      ? 'Re-Apply'
                      : 'Apply'}
                </Button>
                {record?.latestApplication?.[0]?.status ===
                  ApplicationStatusEnum.Declined ||
                record?.latestApplication?.[0]?.status ===
                  ApplicationStatusEnum.PENDING ? (
                  <Typography.Paragraph
                    onClick={(e) => {
                      e.stopPropagation();
                      if (setSelectedLicenseDetails)
                        setSelectedLicenseDetails({
                          ...(record?.latestApplication?.[0] || {}),
                          licenseConfigurations: record?.licenseConfigurations,
                          license: {
                            stateName: StateConstants[record?.stateCode],
                            status: record?.status,
                          },
                        });
                    }}
                    style={{
                      color: '#2286FF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      marginTop: '4px',
                      textAlignLast: 'center',
                      fontFamily: 'Figtree !important',
                    }}
                  >
                    Learn more
                  </Typography.Paragraph>
                ) : null}
              </Tooltip>
            </div>
          ) : null}
        </div>
      );
    },
  });

  return (
    <>
      {allLicenses && !allLicenses?.isOwnerDetailsFilled ? (
        <WarningBanner type={WarningBannerType.OWNERSHIP_DETAILS} />
      ) : null}
      <Table
        columns={columns as any}
        dataSource={allLicenses?.agencyLicenses}
        pagination={false}
        loading={!!isApplying}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, (a, b, c, sort) => refetch(sort));
          setSortValue(sort);
        }}
        className="table-sort-center agency-license-table"
      />

      {/* <Collapse
        items={collapseItems(
          allLicenses,
          isApplying,
          applyLicenses,
          (path: string) => {
            navigate(path);
          },
          setSelectedLicenseDetails
        )}
        activeKey={collapseKeys}
        onChange={onCollapseChange}
        className={
          collapseKeys.includes(
            allLicenses?.agencyLicenses?.[0]?.stateCode || ''
          )
            ? 'collapse-first-child-open'
            : ''
        }
      /> */}
      <LicenseModal
        visible={!!selectedLicenseDetails}
        setVisible={() => {
          setSelectedLicenseDetails(null);
        }}
        agentLicenseDetails={{
          ...selectedLicenseDetails,
        }}
        portal={PortalType.AGENT}
        showTransactionDetails={false}
        autoRenewEnabled={false}
      />
    </>
  );
};

export default AllLicensesAgencyList;

export const collapseItems = (
  allLicenses: AllLicensesAgencyResponseType | null,
  loading?: string,
  applyLicenses?: (licenseId: string) => void,
  navigate?: (path: string) => void,
  setSelectedLicenseDetails?: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!allLicenses) return [];
  // populating stateName since it will not be present in BE v2
  allLicenses.agencyLicenses = allLicenses.agencyLicenses.map((itr) => ({
    ...itr,
    stateName: getStateNameFromCode((itr as any).stateCode),
  }));

  const licenses = groupBy(
    allLicenses?.agencyLicenses?.sort((a, b) =>
      a?.stateName?.localeCompare(b?.stateName)
    ),
    'stateName'
  );

  return Object.entries(licenses).map(([k, value]) => {
    const data = value?.[0];
    const status = getStatus(value);

    const uniqueIds = new Set();
    const drlp = value
      .flatMap((d) => d?.drlps)
      .filter((item) => {
        if (uniqueIds.has(item?.npn)) {
          return false;
        } else {
          uniqueIds.add(item?.npn);

          return true;
        }
      });

    const color = listColors.filter((e) =>
      e.type.includes(status?.toLowerCase())
    )?.[0];

    const isBackgroundQnsAnswered = value?.some(
      (d) => d?.bgQuestionsEngineLight
    );

    return {
      key: data.stateCode,
      label: (
        <div style={{ display: 'flex', gap: 12 }}>
          <div className="agency-licenses-list-left-pane agency-list-border">
            <div
              style={{
                fontSize: 18,
                fontWeight: 500,
                lineHeight: '20px',
                display: 'flex',
              }}
            >
              {data.stateName}{' '}
              {statesWithAffiliation.includes(data.stateName) ? (
                <div>
                  <Tooltip
                    title={`We do not presently offer support for ${data.stateName}.`}
                  >
                    <QuestionCircleOutlined
                      style={{
                        marginLeft: '8px',
                        cursor: 'pointer',
                        color: '#97AABF',
                      }}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <div
              style={{
                ...(color
                  ? {
                      border: `1px solid ${color.borderColor}`,
                      backgroundColor: color.color,
                      borderRadius: 26,
                      padding: '2px 6px',
                      marginBottom: 8,
                      marginTop: 18,
                      fontSize: 12,
                      display: 'inline-block',
                      maxWidth: 'fit-content',
                      textTransform: 'capitalize',
                    }
                  : {}),
              }}
            >
              {status === LicenseStatus.Active
                ? 'Active'
                : status === 'pending'
                  ? 'Pending'
                  : status === 'missing'
                    ? 'Missing Loas'
                    : 'Inactive'}
            </div>
          </div>
          <div
            className="agency-licenses-list-right-pane"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 12,
                    color: drlp?.length ? '#848484' : '#ABABAB',
                  }}
                >
                  {drlp?.length ? 'DRLP’s Name:' : 'No DRLP’s Tracked in NIPR'}
                </div>
                {drlp?.length ? (
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', columnGap: 12 }}
                  >
                    {drlp?.map((data: any, index: number) => {
                      if (data) {
                        return (
                          <div
                            key={index}
                            style={{
                              margin: '12px 0',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '20px',
                            }}
                          >
                            <LicensedProducer
                              text={getAssignedDrlp({
                                ...data,
                              })}
                              notClosable={true}
                              onClose={() => {}}
                              noMargin={true}
                            />
                          </div>
                        );
                      }
                    })}
                    {/* <p style={{ margin: '6px 0' }}>{data.lastName}</p> */}
                  </div>
                ) : null}
              </div>
              {status !== LicenseStatus.Active ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 'fit-content',
                    gap: 24,
                  }}
                >
                  <Tooltip
                    title={
                      !drlp?.length
                        ? 'Please assign drlp for this state'
                        : !isBackgroundQnsAnswered
                          ? 'Please fill background questions before applying for this license.'
                          : !allLicenses?.isOwnerDetailsFilled
                            ? 'Please fill the ownership details to proceed forward with the agency licenses application'
                            : ''
                    }
                  >
                    <Button
                      type="primary"
                      id="agency-all-licenses-apply-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (applyLicenses) applyLicenses(data.id);
                      }}
                      style={{
                        minWidth: 120,
                        height: 22,
                      }}
                      disabled={
                        !!loading ||
                        status === 'pending' ||
                        statesWithAffiliation.includes(data.stateName) ||
                        !drlp?.length ||
                        !isBackgroundQnsAnswered ||
                        !allLicenses?.isOwnerDetailsFilled
                      }
                      loading={loading === data.id}
                    >
                      {data?.latestApplication?.[0]?.status === 'declined'
                        ? 'Re-Apply'
                        : 'Apply'}
                    </Button>
                    {data?.latestApplication?.[0]?.status === 'declined' ? (
                      <Tooltip title="View Details">
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (setSelectedLicenseDetails)
                              setSelectedLicenseDetails({
                                ...(data?.latestApplication?.[0] || {}),
                                licenseConfigurations:
                                  data?.licenseConfigurations,
                                license: {
                                  stateName: data?.stateName,
                                  status: data?.status,
                                },
                              });
                          }}
                          style={{ marginLeft: 12 }}
                          className="button primary-button"
                        >
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Tooltip>
                </div>
              ) : null}
            </div>
            <p
              style={{
                margin: '6px 0',
                display: 'flex',
                gap: 3,
                fontWeight: 400,
                width: 'fit-content',
                height: 24,
                fontSize: 12,
                lineHeight: '20px',
                alignItems: 'center',
                backgroundColor: isBackgroundQnsAnswered
                  ? '#D3E7FF'
                  : 'rgba(252, 239, 207, 1)',
                border: `1px solid ${
                  isBackgroundQnsAnswered ? '#437EE1' : 'rgba(255, 201, 66, 1)'
                }`,
                // backgroundColor: '#D3E7FF',
                // border: `1px solid #437EE1`,
                padding: '6px 12px',
                color: 'rgba(29, 44, 80, 1)',
                borderRadius: 16,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (navigate)
                  navigate(
                    RouteConstants.agencyGeneralProfile.path +
                      `?tab=${GeneralTabType.BACKGROUND_QUESTIONS}`
                  );
              }}
            >
              <FileTextOutlined />
              Background Questions
            </p>
          </div>
        </div>
      ),
      children: (
        <div style={{ display: 'flex' }}>
          <div className="agency-list-border"></div>
          <div className="agency-licenses-list-right-pane">
            <ListAgentDetails
              licenseDetails={value}
              styles={{ padding: '0 12px' }}
              stateCode={value?.[0]?.stateCode}
            />
          </div>
        </div>
      ),
    };
  });
};
