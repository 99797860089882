import {
  AutoComplete,
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Row,
  Skeleton,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { ReactNode, useEffect, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { applySort, capitalizeFirstLetter } from '../../../utils/common.utils';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';

import { ActionableLicenseResponseError } from '../../../types/response/actionable-license.type';
import { AgentOverview } from '../../../types/data/allAgents.type';
import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import { ApplicationTypeEnumInBackend } from '../../../enums/applicationType.enum';
import ApplyLicenseErrorModal from '../../../components/actionableLicenseModal/applyLicenseErrorModal';
import ApplyLicenseModal from '../../../utils/modal/licenses/actionable-licenses/apply-license.modal';
import AssociatedAgent from '../../../components/licenses/associatedAgent';
import EngineLights from '../../../components/licenses/engineLights';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { IndividualApplyButton } from './individualApplyButton';
import { InfoCircleOutlined } from '@ant-design/icons';
import LcLoaCell from '../../../components/licenses/lcLoaCell';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import LicenseModal from '../../../utils/modal/application-status/applictionStatus.modal';
import LicenseStatusCell from '../../../components/licenses/licenseStatusCell';
import { LicensesService } from '../../../services/licenses.service';
import { LoaStatus } from '../../../enums/loaStatus.enum';
import { ObjectSuccessResponse } from '../../../types/response/objectSuccessResponse.type';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { PayeeEnum } from '../../../enums/payee.enum';
import PaymentConfigModal from '../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../enums/paymentType.enum';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { PortalType } from '../../../enums/portalType.enum';
import PreApplicationErrorModal from '../../../components/preApplicationErrorModal/preApplicationErrorModal';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../components/licenses/stateCell';
import { StateConstants } from '../../../constants/state.constants';
import { SuccessMessageConstants } from '../../../constants/succes-message.constants';
import { adminStore } from '../../../stores/admin.store';
import { getProducerName } from '../../agents/agentsOverview/agencyAgentOverview';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { produce } from 'immer';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
export enum Tab {
  MISSING_LICENSES = 'missing',
  MISSING_LOAS = 'missing_loas',
}

const getDaysToExpireTooltip = (dates: any, expiredDate: any) => {
  if (expiredDate > 0) {
    const hoursToExpiry = Math.floor(expiredDate * 24);
    return `${
      hoursToExpiry === 1 ? `${hoursToExpiry} hour` : `${hoursToExpiry} hours`
    } for license to expire`;
  }
  expiredDate = Math.floor(expiredDate);
  const {
    renewalStartDate,
    renewalEndDate,
    lateRenewalStartDate,
    lateRenewalEndDate,
    reinstatementStartDate,
    reinstatementEndDate,
  } = dates || {};

  const currentDate = new Date();

  const isWithinRenewal =
    currentDate >= new Date(renewalStartDate) &&
    currentDate <= new Date(renewalEndDate);

  const isWithinLateRenewal =
    currentDate >= new Date(lateRenewalStartDate) &&
    currentDate <= new Date(lateRenewalEndDate);

  const isWithinReinstatment =
    currentDate >= new Date(reinstatementStartDate) &&
    currentDate <= new Date(reinstatementEndDate);

  return isWithinRenewal
    ? `License is in the renewal period`
    : isWithinLateRenewal
    ? 'License is in the late renewal period'
    : isWithinReinstatment
    ? 'License is in the reinstatment period'
    : `License has expired ${Math.abs(expiredDate)} days ago.`;
};

const tooltipMessage =
  'View all active licenses with renewal option enabled in this section. If auto apply is activated, licenses will automatically apply 45 days prior to expiration, provided that all requirements are fulfilled.';

function RenewalLicenses() {
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: ReactNode) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const [preApplicationModalVisibility, setPreApplicationModalVisibility] =
    useState(false);
  const [preApplicationModalRecord, setPreApplicationModalRecord] =
    useState<LicenseApplicationResponse>();
  const [licenses, setLicenses] = useState<LicenseApplicationResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>('');
  const [autoRenewEnabled, setAutoRenewEnabled] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedLicensesIds, setSelectedLicensesId] = useState<any[]>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<
    LicenseApplicationResponse[]
  >([]);
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [currentAgent, setCurrentAgent] = useState<AgentOverview>();
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);

  //filters
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<Object>({});
  const [controlledFilters, setControlledFilters] = useState([]);
  const [sortValue, setSortValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [expiringCount, setExpiringCount] = useState(0);
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);

  useEffect(() => {
    adminStore.agency?.id && fetchLicenses();
  }, [adminStore.account, queryText, searchType]);

  const showCustomError = (msg: { message: string; description?: string }) => {
    api['error']({
      message: msg.message,
      description: msg.description,
    });
  };

  useEffect(() => {
    refreshData && adminStore.agency?.id && fetchLicenses();
  }, [refreshData]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchLicenses('', 1);
    setPageNumber(1);
  }, [requestBody]);

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const deleteAppliedLicense = async (licenseId: string) => {
    api['success']({
      message: 'License application submitted',
      description: SuccessMessageConstants.LICENSE_APPLICATION_IN_PROGESS,
    });
    fetchLicenses();
  };

  const updateLicenseById = (
    licenseId: string,
    record: LicenseApplicationResponse
  ) => {
    const newLicenses = produce(licenses, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].license.id === licenseId) {
          draft[i] = { ...record };
          break;
        }
      }
    });
    setLicenses(newLicenses);
  };

  const setIsLoadingForGivenLicense = (
    licenseId: string,
    isLoading: boolean
  ) => {
    const newLicenses = produce(licenses, (draft) => {
      draft.forEach((itr) => {
        if (itr.id === licenseId) itr.isLoading = isLoading;
      });
    });
    setLicenses(newLicenses);
  };

  const refreshLicenseById = async (licenseId: string) => {
    try {
      setIsLoadingForGivenLicense(licenseId, true);
      const token = await getAccessTokenSilently();

      fetchLicenses(); // to get error messages when failed

      const response: ObjectSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.findLatestApplicationForGivenLicense(
          token,
          licenseId
        );
      if (response?.data) {
        updateLicenseById(licenseId, response.data);
      } else setIsLoadingForGivenLicense(licenseId, false);
    } catch (e) {
      setIsLoadingForGivenLicense(licenseId, false);
    }
  };

  const fetchLicenses = async (
    searchTextProp?: string,
    pageNumberProp?: number,
    pageSizeProp?: number,
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;

    try {
      const token = await getAccessTokenSilently();
      setIsLoading(true);
      let payload = requestBody;

      const response: PaginatedSuccessResponse<LicenseApplicationResponse> =
        await LicensesService.getRenewalLicensesForAgency(
          token,
          pageSizeProp || pageSize,
          pageNumberProp || pageNumber,
          searchTextProp ? searchTextProp : queryText || '',
          searchType ? searchType : searchType || '',
          { ...(payload || {}), ...(sort || sortValue || {}) }
        );
      if (response.data) {
        setTotalCount(response.totalCount);
        setLicenses(response.data);
        setExpiringCount(response?.expiryDateWithin45Days || 0);
      } else {
        setTotalCount(0);
      }
    } catch (error: any) {
      console.error('Error:', error);
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }
    } finally {
      setRefreshData(false);
      if (!errorTypeRequestCancel) setIsLoading(false);
      setLoading(false);
    }
  };

  const fetchFilters: () => Promise<FilterFieldsMetaData[]> = async () => {
    try {
      setIsFilterInfoLoading(true);
      const defaultRequestBody = {
        agency: adminStore.agency?.id,
        isLatest: true,
      };
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await LicensesService.getFilterInfoForRenewals(
          token,
          defaultRequestBody
        );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.RENEWALS
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }

    return [];
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleRowClick = (record: any) => {
    setSelectedLicenseDetails(record);
    setAutoRenewEnabled(record.agent?.autoRenewEnabled);
    setApplicationModalVisible(true); // Open the modal
  };

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenses(queryText, newPageNumber, newPageSize);
  };

  const columns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'stateCode',
      sorter: true,
      key: 'stateCode',
      width: 140,
      render: (text: string, record: any) => (
        <StateCell
          state={StateConstants[text]}
          licenseNumber={record.licenseNumber}
        />
      ),
    },
    {
      title: 'Producer',
      dataIndex: 'producerDetails',
      sorter: true,
      width: 140,
      key: 'licenseProducer.name',
      render: (producer: any, record: LicenseApplicationResponse) => {
        return (
          <AssociatedAgent
            agentId={producer.producerId}
            agentName={producer.name}
            isLicenseProcessing={producer?.processingFlags?.isLicenseProcessing}
            isAssignmentProcessing={
              producer?.processingFlags?.isAssignmentProcessing
            }
            isLoading={record.isLoading}
            id={`${
              IdConstants.LICENSES.ACTIONABLE_LICENSES.TABLE.ASSOCIATED_AGENT +
              '-' +
              record.license.licenseNumber
            }`}
          />
        );
      },
    },
    {
      title: 'License Status',
      dataIndex: 'status',
      sorter: true,
      key: 'status',
      width: 120,

      render: (_: any, record: LicenseApplicationResponse) => (
        <LicenseStatusCell licenseStatus={record.license.status} />
      ),
    },
    // {
    //   title: 'Initial payment account',
    //   dataIndex: 'paymentConfig',
    //   key: 'producerDetails.paymentConfig.licenseInitialPayee',
    //   sorter: true,
    //   width: '12%',
    //   render: (_: any, record: any, index: number) => {
    //     return (
    //       <Row
    //         id={
    //           IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
    //           record.id +
    //           '-other-line-only-agents'
    //         }
    //         style={{
    //           justifyContent: 'space-between',
    //         }}
    //       >
    //         {record.producerDetails.paymentConfig?.licenseInitialPayee ? (
    //           getProducerName(
    //             record.producerDetails.paymentConfig?.licenseInitialPayee
    //           )
    //         ) : (
    //           <span style={{ color: '#92A69E', fontSize: '12px' }}>
    //             Payee not selected
    //           </span>
    //         )}
    //       </Row>
    //     );
    //   },
    //   // shouldCellUpdate: () => true,
    //   // onCell: (record, index) => {
    //   //   return {
    //   //     onClick: () => {
    //   //       if (!record.isLoading && selectedLicenses.length === 0) {
    //   //         setCurrentAgent({
    //   //           ...record.agent,
    //   //           id: record.agent.id || record.agent._id,
    //   //         });
    //   //         setPaymentConfigModalVisibility(true);
    //   //       }
    //   //     },
    //   //   };
    //   // },
    // },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'paymentConfig',
      sorter: true,
      key: 'producerDetails.paymentConfig.licenseRenewalPayee',
      width: '120px',
      render: (_: any, record: any, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            {record.producerDetails.paymentConfig?.licenseRenewalPayee ? (
              getProducerName(
                record.producerDetails.paymentConfig?.licenseRenewalPayee
              )
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}
          </Row>
        );
      },
      // shouldCellUpdate: () => true,
      // onCell: (record, index) => {
      //   return {
      //     onClick: () => {
      //       if (!record.isLoading && selectedLicenses.length === 0) {
      //         setCurrentAgent({
      //           ...record.agent,
      //           id: record.agent.id || record.agent._id,
      //         });
      //         setPaymentConfigModalVisibility(true);
      //       }
      //     },
      //   };
      // },
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      sorter: true,
      width: 220,

      render: (loas: any, record: any) => (
        <Row align="middle">
          <LcLoaCell
            licenseClass={record.licenseConfigurations?.licenseClass}
            loaDetails={loas?.loaDetails}
            currentTab={Tab.MISSING_LICENSES}
            requiredStatus={[
              LoaStatus.ACTIVE,
              LoaStatus.CANCELLED,
              LoaStatus.INACTIVE,
              LoaStatus.PENDING,
            ]}
          />
        </Row>
      ),
    },
    {
      title: 'Days To Expire',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      sorter: true,
      width: 180,
      render: (date: any, record: any) => {
        if (!date) return <div> - </div>;
        const expiredDate =
          (new Date(date).valueOf() - new Date().valueOf()) /
          (1000 * 60 * 60 * 24);
        const expiryDateMomentObj = moment(date);
        return (
          <div
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {expiredDate < 0 ? (
              <span style={{ color: 'red', marginLeft: 12 }}>Expired</span>
            ) : (
              <>
                <Tooltip
                  title={`Expires on ` + expiryDateMomentObj.format('ll')}
                >
                  <Typography.Paragraph
                    style={{
                      fontSize: '14px',
                      marginBottom: '0.25em',
                    }}
                  >
                    <>
                      {expiryDateMomentObj.format('ll')} {'    '}
                    </>
                    ({capitalizeFirstLetter(expiryDateMomentObj.fromNow())})
                  </Typography.Paragraph>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Application Status',
      dataIndex: 'latestApplicationStatus',
      sorter: true,
      key: 'latestApplicationStatus',
      align: 'center',
      width: 160,
      render: (text: string, record: any) => {
        return record.isLoading ? (
          <Skeleton.Button active size="small" />
        ) : (
          <Row
            align="middle"
            style={{ textAlign: 'center' }}
            onClick={() => {
              if (text) {
                handleRowClick(record);
              }
            }}
          >
            <ApplicationStatusCell
              applicationStatus={
                text
                  ? text.charAt(0).toUpperCase() + text.slice(1)
                  : ApplicationStatusEnum.Not_Applied
              }
            />
          </Row>
        );
      },
    },
    {
      width: 180,
      render: (record: LicenseApplicationResponse) => {
        return (
          <Col
            style={{
              alignItems: 'center',
              rowGap: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EngineLights
              payer={
                record.applicationType === ApplicationTypeEnumInBackend.creation
                  ? record.agent?.paymentConfig?.initialPaymentPayee
                  : record.agent?.paymentConfig?.renewalPaymentPayee
              }
              setPayerCallBack={() => {
                if (selectedLicenses.length === 0) {
                  setCurrentAgent({
                    ...record.agent,
                    id: record.agent.id || record.agent._id,
                  });
                  // initial payer missing
                  if (
                    record.applicationType ===
                    ApplicationTypeEnumInBackend.creation
                  ) {
                    if (
                      record.agent?.paymentConfig?.initialPaymentPayee !==
                      PayeeEnum.AGENCY
                    )
                      setPaymentConfigModalVisibility(true);
                    else if (
                      // payment type is missing
                      !(
                        record.agent?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.CAP ||
                        record.agent?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.FULL
                      )
                    ) {
                      setPaymentConfigModalVisibility(true);
                    }
                  } else {
                    // renewal payer missing
                    if (
                      record.agent?.paymentConfig?.renewalPaymentPayee !==
                      PayeeEnum.AGENCY
                    )
                      setPaymentConfigModalVisibility(true);
                    else if (
                      // payment type is missing
                      !(
                        record.agent?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.CAP ||
                        record.agent?.paymentConfig?.paymentType ===
                          PaymentTypeEnum.FULL
                      )
                    ) {
                      setPaymentConfigModalVisibility(true);
                    }
                  }
                }
              }}
              record={record}
            />
            <IndividualApplyButton
              payer={
                record.applicationType === ApplicationTypeEnumInBackend.creation
                  ? record.agent?.paymentConfig?.initialPaymentPayee
                  : record.agent?.paymentConfig?.renewalPaymentPayee
              }
              selectedLicensesLength={selectedLicenses.length}
              record={record}
              currentTab={Tab.MISSING_LICENSES}
              setRefreshData={setRefreshData}
              deleteAppliedLicense={deleteAppliedLicense}
              setDisableApplyButton={setDisableApplyButton}
              disableApplyButton={disableApplyButton || !!record.isLoading}
              showError={showCustomError}
              setApplyLicenseErrorModalVisibility={
                setApplyLicenseErrorModalVisibility
              }
              setErrors={setErrors}
              setPreApplicationModalVisibility={
                setPreApplicationModalVisibility
              }
              setPreApplicationModalRecord={setPreApplicationModalRecord}
              applyErrorCallback={refreshLicenseById}
              isRenewalPage={true}
            />
          </Col>
        );
      },
    },
  ];

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };
  const pageName = PlausiblePageConstants.AGENCY_RENEWALS;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      {contextHolder}
      {/* <p className="reset-icon-size card-wrapper">
        {' '}
        <span>
          <InfoCircleOutlined /> Licenses going to expire in the next 45 days -{' '}
          <span style={{ color: 'var(--primary-color)' }}>
            <b>{expiringCount || 0}</b>
          </span>
        </span>{' '}
      </p> */}
      <div style={{ width: '100%', height: 'fit-content' }}>
        <div
          className="reset-icon-size"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography.Text
            type="secondary"
            style={{ marginBottom: 10, fontSize: 14 }}
          >
            <InfoCircleOutlined /> {tooltipMessage}{' '}
          </Typography.Text>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,
              columnGap: 10,
            }}
          >
            <AutoComplete
              options={options}
              style={{ width: '100%' }}
              disabled={loading}
              value={getSearchDisplayText(
                searchType,
                displaySearchType,
                searchText
              )}
              onSelect={handleOnSelect}
              onChange={handleOnChange}
            >
              <Search
                id={IdConstants.SEARCH_INPUT + '-actionable-licenses'}
                placeholder="Search Licenses by State, Producer, LOAs, License Class or License Number"
                size="middle"
                style={{
                  width: '100%',
                }}
                onSearch={handleOnSearch}
              />
            </AutoComplete>
            {selectedLicenses.length > 1 && (
              <Button
                id={IdConstants.LICENSES.ACTIONABLE_LICENSES.APPLY_ALL_LICENSES}
                className="button"
                onClick={() => {
                  setIsUpdateModalVisible(!isUpdateModalVisible);
                }}
                disabled={
                  // currentTab === Tab.INACTIVE_LICENSES
                  true
                    ? selectedLicenses.some(
                        (itr) =>
                          itr?.agent?.paymentConfig?.renewalPaymentPayee !==
                          PayeeEnum.AGENCY
                      )
                    : selectedLicenses.some(
                        (itr) =>
                          itr?.agent?.paymentConfig?.initialPaymentPayee !==
                          PayeeEnum.AGENCY
                      )
                }
              >
                Apply All Licenses
              </Button>
            )}
            <FilterButton
              {...{
                filterInfoLoading,
                activeData: licenses,
                requestBody,
                isLoading,
                setIsFilterVisible,
                pageName,
              }}
            />
          </div>
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters, pageName }}
        />
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}
          className="table-layout-v2 relative"
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Table
                rowKey={(record, index) => record.id + index}
                columns={columns}
                dataSource={licenses}
                onChange={(pagination, filters, sorter) => {
                  const sort = applySort(sorter, fetchLicenses);
                  setSortValue(sort);
                }}
                pagination={false}
                loading={isLoading}
                scroll={{
                  y: 'calc(100vh - 310px)',
                }}
              />
              {!isEmpty(licenses) && (
                <Row justify="end">
                  <Pagination
                    total={totalCount}
                    pageSize={pageSize}
                    current={pageNumber}
                    showSizeChanger={false}
                    onChange={onPageChange}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    disabled={isLoading}
                  />
                </Row>
              )}
            </>
          )}
        </div>

        <ApplyLicenseModal
          visible={isUpdateModalVisible}
          setVisible={setIsUpdateModalVisible}
          licenseDetails={selectedLicenses}
          licenseIds={selectedLicensesIds}
          selectedFilter={Tab.MISSING_LICENSES}
          successCallback={() => {
            setSelectedLicenses([]);
            setSelectedLicensesId([]);
            fetchLicenses();
          }}
        />
        <LicenseModal
          visible={applicationModalVisible}
          setVisible={setApplicationModalVisible}
          agentLicenseDetails={{
            ...selectedLicenseDetails?.latestApplication,
            ...selectedLicenseDetails,
          }}
          portal={PortalType.AGENCY}
          showTransactionDetails={false}
          autoRenewEnabled={autoRenewEnabled}
        />
        <PaymentConfigModal
          agents={currentAgent ? [currentAgent] : undefined}
          visible={paymentConfigModalVisibility}
          handleVisibility={() => {
            setPaymentConfigModalVisibility(false);
          }}
          successCallback={() => {
            if (adminStore.agency?.id) {
              fetchLicenses(undefined, 1);
              setPageNumber(1);
            }
          }}
        />
        <ApplyLicenseErrorModal
          applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
          setApplyLicenseErrorModalVisibility={
            setApplyLicenseErrorModalVisibility
          }
          errors={errors}
          fetchLicense={fetchLicenses}
        />
        {preApplicationModalRecord && (
          <PreApplicationErrorModal
            showError={showError}
            modalVisibility={preApplicationModalVisibility}
            setModalVisibility={setPreApplicationModalVisibility}
            record={preApplicationModalRecord}
            deleteAppliedLicense={deleteAppliedLicense}
            currentTab={Tab.MISSING_LICENSES}
            setDisableApplyButton={setDisableApplyButton}
            setApplyLicenseErrorModalVisibility={
              setApplyLicenseErrorModalVisibility
            }
            setErrors={setErrors}
            setPayerCallBack={() => {
              if (selectedLicenses.length === 0) {
                setCurrentAgent({
                  ...preApplicationModalRecord.agent,
                  id:
                    preApplicationModalRecord.agent?.id ||
                    preApplicationModalRecord.agent?._id,
                });
                // initial payer missing
                if (
                  preApplicationModalRecord.applicationType ===
                  ApplicationTypeEnumInBackend.creation
                ) {
                  if (
                    preApplicationModalRecord.agent?.paymentConfig
                      ?.initialPaymentPayee !== PayeeEnum.AGENCY
                  )
                    setPaymentConfigModalVisibility(true);
                  else if (
                    // payment type is missing
                    !(
                      preApplicationModalRecord.agent?.paymentConfig
                        .paymentType === PaymentTypeEnum.CAP ||
                      preApplicationModalRecord.agent?.paymentConfig
                        .paymentType === PaymentTypeEnum.FULL
                    )
                  )
                    setPaymentConfigModalVisibility(true);
                } else {
                  // renewal payer missing
                  if (
                    preApplicationModalRecord.agent?.paymentConfig
                      ?.renewalPaymentPayee !== PayeeEnum.AGENCY
                  )
                    setPaymentConfigModalVisibility(true);
                  else if (
                    // payment type is missing
                    !(
                      preApplicationModalRecord.agent?.paymentConfig
                        .paymentType === PaymentTypeEnum.CAP ||
                      preApplicationModalRecord.agent?.paymentConfig
                        .paymentType === PaymentTypeEnum.FULL
                    )
                  )
                    setPaymentConfigModalVisibility(true);
                }
              }
            }}
          />
        )}

        <SimpleFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setApplyFilter={() => {}}
          setRequestBody={setRequestBody}
          requestBody={requestBody}
          filterLoader={filterInfoLoading}
          visibility={isFilterVisible}
          setVisibility={() => {
            setIsFilterVisible(false);
          }}
          fetchFilters={() => {
            return new Promise((resolve) =>
              resolve([] as FilterFieldsMetaData[])
            );
          }}
          clearFilters={() => {
            clearFilters();
          }}
          controlledFilters={controlledFilters}
          pageName={pageName}
          filterPage={PageConstants.RENEWALS}
        />
      </div>
    </div>
  );
}

export default withRoles(RenewalLicenses, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
