export enum AgencyLicenseType {
  ACTIVE = 'active',
  NEEDS_ATTENTION = 'needsAttention',
  RENEWALS = 'renewal',
}

export enum ProducerLicenseType {
  ACTIVE = 'active',
  NEEDS_ATTENTION = 'needsAttention',
  RENEWALS = 'renewal',
}
