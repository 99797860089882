import { Card, ConfigProvider, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';

import { AUTO_RENEWAL_INFO } from '../../../../constants/info.constant';
import ErrorModal from '../../../../components/common/errorModal/errorModal';
import { IdConstants } from '../../../../constants/id.constants';
import { IndividualOnboardSteps } from '../../../../enums/onboardSteps.enum';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PayeeEnum } from '../../../../enums/payee.enum';
import PaymentConfigModal from '../../../../components/paymentConfig/paymentConfigModal';
import { PaymentTypeEnum } from '../../../../enums/paymentType.enum';
import TextWithIcon from '../../../../components/common/textWithIcon/textWithIcon';
import { TooltipWrapper } from '../../agentsOverview/agentOverview/paymentConfig';
import { getProducerName } from '../../agentsOverview/agencyAgentOverview';
import { individualOnboardStore } from '../../../../stores/individualOnboardStore';
import { multipleOnboardStore } from '../../../../stores/multipleOnboardStore';
import { observer } from 'mobx-react-lite';
import penIcon from '../../../../assets/icons/pen.svg';

function MultipleOnboardingPaymentConfig() {
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [paymentConfigured, setPaymentConfigured] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (
      (multipleOnboardStore.paymentConfig?.initialPaymentPayee ===
        PayeeEnum.AGENT ||
        multipleOnboardStore.paymentConfig?.initialPaymentPayee ===
          PayeeEnum.AGENCY) &&
      (multipleOnboardStore.paymentConfig?.renewalPaymentPayee ===
        PayeeEnum.AGENT ||
        multipleOnboardStore.paymentConfig?.renewalPaymentPayee ===
          PayeeEnum.AGENCY) &&
      multipleOnboardStore.paymentConfig?.paymentType !== PaymentTypeEnum.NA
    ) {
      setPaymentConfigured(true);
    } else {
      setPaymentConfigured(false);
      individualOnboardStore.setMaxStep(
        IndividualOnboardSteps.CONFIGURE_PAYMENTS
      );
    }

    if (
      multipleOnboardStore.paymentConfig?.initialPaymentPayee ===
        PayeeEnum.AGENT &&
      multipleOnboardStore.paymentConfig?.renewalPaymentPayee ===
        PayeeEnum.AGENT
    ) {
      multipleOnboardStore.paymentConfig.paymentType = PaymentTypeEnum.NA;
      setPaymentConfigured(true);
    }
    multipleOnboardStore.paymentsConfigured = paymentConfigured;
  }, [
    multipleOnboardStore.paymentConfig?.initialPaymentPayee,
    multipleOnboardStore.paymentConfig?.renewalPaymentPayee,
    multipleOnboardStore.paymentConfig?.amountCap,
    multipleOnboardStore.paymentConfig?.paymentType,
    paymentConfigured,
  ]);

  return (
    <div>
      <Row style={{ justifyContent: 'flex-start' }}>
        <div style={{ flexBasis: '40%' }}>
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Initial Payment Account
          </Typography.Paragraph>
          <div
            id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.INIT_PAYMENT}
            style={{ display: 'flex' }}
            onClick={() => {
              setPaymentConfigModalVisibility(true);
            }}
          >
            {
              <img
                src={penIcon}
                style={{ marginRight: '10px', marginTop: '-15px' }}
                alt="pen"
                onClick={() => {
                  setPaymentConfigModalVisibility(true);
                }}
              />
            }
            <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 400 }}>
              {!multipleOnboardStore.paymentConfig?.initialPaymentPayee ? (
                <span style={{ color: '#929292', cursor: 'pointer' }}>
                  Select Initial Payment
                </span>
              ) : (
                getProducerName(
                  multipleOnboardStore.paymentConfig?.initialPaymentPayee
                )
              )}
            </Typography.Paragraph>
          </div>
        </div>
        <div style={{ flexBasis: '40%' }}>
          <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 500 }}>
            Renewal Payment Account
          </Typography.Paragraph>
          <div
            id={IdConstants.AGENTS.INDIVIDUAL_ONBOARDING.RENEW_PAYMENT}
            style={{ display: 'flex' }}
            onClick={() => {
              setPaymentConfigModalVisibility(true);
            }}
          >
            {
              <img
                src={penIcon}
                style={{ marginRight: '10px', marginTop: '-15px' }}
                alt="pen"
                onClick={() => {
                  setPaymentConfigModalVisibility(true);
                }}
              />
            }
            <Typography.Paragraph style={{ fontSize: '14px', fontWeight: 400 }}>
              {!multipleOnboardStore.paymentConfig?.renewalPaymentPayee ? (
                <span style={{ color: '#929292', cursor: 'pointer' }}>
                  Select Renewal Payment
                </span>
              ) : (
                getProducerName(
                  multipleOnboardStore.paymentConfig?.renewalPaymentPayee
                )
              )}
            </Typography.Paragraph>
          </div>
        </div>
      </Row>

      <Card style={{ marginTop: '30px' }}>
        <Typography.Paragraph style={{ fontSize: '16px', fontWeight: 500 }}>
          Setup Auto Apply
          <TextWithIcon
            iconColor="#97BFBF"
            textStyle={{
              fontSize: '12px',
              fontWeight: 500,
              color: '#97BFBF',
            }}
            text={AUTO_RENEWAL_INFO}
            iconAlignment="left"
            icon={<InfoCircleOutlined />}
          />
        </Typography.Paragraph>
        <hr className="secondary-line" />
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <div
            style={{
              display: 'flex',
              marginRight: '20px',
              marginTop:
                multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
                PayeeEnum.AGENT
                  ? '20px'
                  : '0',
              opacity:
                multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
                PayeeEnum.AGENT
                  ? '100%'
                  : '50%',
            }}
          >
            <Typography.Paragraph
              style={{
                fontSize: '16px',
                fontWeight: 500,
                marginRight: '30px',
              }}
            >
              Auto Apply
            </Typography.Paragraph>
            <ConfigProvider theme={{ token: { colorPrimary: '#0588ca' } }}>
              <TooltipWrapper
                isDisabled={
                  multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
                    PayeeEnum.AGENCY ||
                  multipleOnboardStore?.paymentConfig.paymentType ===
                    PaymentTypeEnum.NA
                }
                message={
                  !multipleOnboardStore?.paymentConfig?.renewalPaymentPayee &&
                  multipleOnboardStore?.paymentConfig.paymentType ===
                    PaymentTypeEnum.NA
                    ? 'Auto Apply cannot be modified as Renewal Type and Payment Configuration are not set.'
                    : multipleOnboardStore?.paymentConfig
                        ?.renewalPaymentPayee === PayeeEnum.AGENT
                    ? 'Auto Apply cannot be modified as Renewal Type is set to Producer'
                    : multipleOnboardStore?.paymentConfig?.paymentType ===
                      PaymentTypeEnum.NA
                    ? 'Auto Apply cannot be modified as Payment Configuration is not specified.'
                    : ''
                }
              >
                <Switch
                  id={IdConstants.SWITCH_BUTTON + '-auto-renewal'}
                  disabled={
                    multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
                      PayeeEnum.AGENCY ||
                    multipleOnboardStore.paymentConfig.paymentType ===
                      PaymentTypeEnum.NA
                  }
                  onClick={() => {
                    setPaymentConfigModalVisibility(true);
                  }}
                  checked={
                    multipleOnboardStore.autoRenewEnabled
                      ? multipleOnboardStore.autoRenewEnabled
                      : false
                  }
                />
              </TooltipWrapper>
            </ConfigProvider>
          </div>
          <div>
            {multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
              PayeeEnum.AGENT && (
              <Typography.Paragraph
                style={{
                  fontSize: '16px',
                  fontWeight: 700,
                  color: 'var(--primary-color)',
                }}
              >
                Auto Apply is{' '}
                {multipleOnboardStore.autoRenewEnabled ? 'On' : 'Off'}!
              </Typography.Paragraph>
            )}

            <Typography.Paragraph style={{ fontSize: '16px', fontWeight: 500 }}>
              {multipleOnboardStore.paymentConfig?.renewalPaymentPayee !==
              PayeeEnum.AGENT
                ? ' All the assigned state licenses will be applied automatically for this producer.'
                : 'Producer will have to configure their auto apply settings'}
            </Typography.Paragraph>
          </div>
        </div>
      </Card>

      <PaymentConfigModal
        agents={undefined}
        visible={paymentConfigModalVisibility}
        handleVisibility={() => {
          setPaymentConfigModalVisibility(false);
        }}
        onboardSteps={true}
        agentIds={multipleOnboardStore.bulkAgentsIds}
        fromMultipleOnboard={true}
      />
      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  );
}

export default observer(MultipleOnboardingPaymentConfig);
