import {
  AutoComplete,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import {
  InsuranceLayout,
  Insurancetypes,
} from '../../../utils/modal/application-status/applicationstatus.style';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useState } from 'react';

import ApplicationStatusCell from '../../../components/licenses/applicationStatusCell';
import { ApplicationStatusEnum } from '../../../enums/applicationStatus.enum';
import ApplicationTypeCell from '../../../components/applications/applicationTypeCell';
import { ColumnsType } from 'antd/es/table';
import FeesCell from '../../../components/applications/feesCell';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { LicenseApplicationResponse } from '../../../types/response/license-application.type';
import LicenseModal from '../../../utils/modal/application-status/applictionStatus.modal';
import { LicensesService } from '../../../services/licenses.service';
import { PageConstants } from '../../../constants/page.constants';
import { PaginatedSuccessResponse } from '../../../types/response/paginatedSuccessResponse.type';
import { PortalType } from '../../../enums/portalType.enum';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import StateCell from '../../../components/licenses/stateCell';
import { StateConstants } from '../../../constants/state.constants';
import { SyncOutlined } from '@ant-design/icons';
import { TextDetail } from '../dashboard/dashboard.style';
import { UnresolvedTooltip } from '../../../utils/date.utils';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { applySort } from '../../../utils/common.utils';
import axios from 'axios';
import { getNiprResponseFromLicenseForModal } from '../../../utils/nipr/license.utils';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
function Applications() {
  const [activeData, setActiveData] = useState<LicenseApplicationResponse[]>(
    []
  );
  const [loading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState(10);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [queryText, setQueryText] = useQueryState('search');
  const [errorMessage, setErrorMessage] = useState('');
  const { bearerToken } = useAuth();
  const [searchText, setSearchText] = useState('');
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [selectedLicenseDetails, setSelectedLicenseDetails] =
    useState<LicenseApplicationResponse>();
  const [autoRenewEnabled, setAutoRenewEnabled] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);

  // Adding Filters
  const [requestBody, setRequestBody] = useState<Object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [sortValue, setSortValue] = useState({});
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const refreshPoll = async (id?: string) => {
    const token = await getAccessTokenSilently();
    setIsRefreshing(true);
    try {
      if (id) await LicensesService.getPollingStatusById(id, token);
      else await LicensesService.getAllPollingStatus(token);
      message.success(
        'Polling initiated successfully, please refresh in sometime'
      );
      fetchLicenseDetails();
    } catch (err) {
      message.error('Polling refresh failed. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const Applicationcolumns: ColumnsType<LicenseApplicationResponse> = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'license.stateCode',
      sorter: true,

      render: (_: any, record: LicenseApplicationResponse) => (
        <StateCell state={StateConstants[record.license.stateCode]} />
      ),
    },
    {
      title: 'License Class & LOAs',
      dataIndex: 'licenseClass',
      key: 'licenseConfigurations.licenseClass',
      sorter: true,

      render: (_: any, record: LicenseApplicationResponse) => {
        return (
          <>
            <TextDetail fontSize={'12px'}>
              {appStateInfoStore.getLcNameFromCode(record.license.licenseClass)}
            </TextDetail>
            <Insurancetypes>
              {record?.loaIds &&
                record?.loaIds.map((loa: string) => (
                  <InsuranceLayout>
                    <TextDetail
                      style={{ textAlign: 'center' }}
                      fontSize={'12px'}
                    >
                      {appStateInfoStore.getLoaNameFromCode(loa)}
                    </TextDetail>
                  </InsuranceLayout>
                ))}
            </Insurancetypes>
          </>
        );
      },
    },
    {
      title: 'Fee',
      dataIndex: 'latestApplication',
      key: 'gatewayTransaction.niprGatewaySuccessResponse.stateFee',
      sorter: true,
      width: '100px',
      render: (_: any, record: any) => {
        const stateFee =
          record?.gatewayTransaction?.niprGatewaySuccessResponse?.stateFee || 0;
        const transFee =
          record?.gatewayTransaction?.niprGatewaySuccessResponse?.transFee || 0;
        const fees = stateFee + transFee;
        if (!fees) return '-';
        return <FeesCell fees={fees} isEstimate={false} />;
      },
    },
    {
      title: 'Application Type',
      dataIndex: 'latestApplication',
      key: 'applicationType',
      sorter: true,
      render: (_: any, record: LicenseApplicationResponse) => (
        <ApplicationTypeCell applicationType={record?.applicationType} />
      ),
    },
    {
      title: 'Applied on',
      dataIndex: 'latestApplication',
      key: 'applyDate',
      sorter: true,

      render: (_: any, record: LicenseApplicationResponse) => (
        <Row align="middle" style={{ textAlign: 'center' }}>
          <Typography.Paragraph
            style={{
              fontSize: '12px',
              fontWeight: 400,
              whiteSpace: 'nowrap',
              marginBottom: 0,
            }}
          >
            {record?.applyDate
              ? moment(new Date(record?.applyDate)).format('lll')
              : '-'}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Polled On',
      dataIndex: 'latestApplication',
      key: 'latestApplication',
      width: 180,
      render: (_: any, record: LicenseApplicationResponse) => {
        const polls = record.gatewayTransactionPolls;
        const lastPolledAt =
          polls && polls?.length > 0 && polls[polls.length - 1].createdAt;
        return (
          <Row align="middle" style={{ textAlign: 'center' }}>
            <Typography.Paragraph
              style={{
                fontSize: '12px',
                fontWeight: 400,
                whiteSpace: 'nowrap',
                marginBottom: 0,
              }}
            >
              {lastPolledAt ? (
                moment(new Date(lastPolledAt)).format('lll')
              ) : (
                <>
                  {record?.status === ApplicationStatusEnum.Declined ? (
                    'Polling Complete'
                  ) : (
                    <p
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 12,
                      }}
                    >
                      <Tooltip title={UnresolvedTooltip()}>Unresolved </Tooltip>
                      <Tooltip
                        title={
                          isRefreshing
                            ? 'Refreshing...'
                            : 'Initiate an individual poll request'
                        }
                      >
                        {isRefreshing ? (
                          <Spin size="small" />
                        ) : (
                          <SyncOutlined
                            style={{ fontSize: 12, color: '#0588ca' }}
                            onClick={() => refreshPoll(record.id)}
                            className="cursor-pointer primary-color"
                          />
                        )}
                      </Tooltip>
                    </p>
                  )}
                </>
              )}
            </Typography.Paragraph>
          </Row>
        );
      },
    },
    {
      title: 'Application Status',
      dataIndex: 'latestApplication',
      key: 'status',
      width: '175px',
      sorter: true,
      align: 'center',
      fixed: 'right',
      render: (_: any, record: LicenseApplicationResponse) => {
        return (
          <Row
            id={
              IdConstants.LICENSES.APPLICATIONS.TABLE.VIEW_DETAILS +
              '-' +
              record.license.licenseNumber
            }
            align="middle"
            style={{
              textAlign: 'center',
            }}
            onClick={() => {
              if (record?.status !== ApplicationStatusEnum.Not_Applied)
                handleRowClick(record);
            }}
          >
            <ApplicationStatusCell
              applicationStatus={record?.status}
              showError={
                getNiprResponseFromLicenseForModal(record).errors.length > 0
              }
            />
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    fetchLicenseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bearerToken, queryText, searchType]);

  const handleRowClick = (record: LicenseApplicationResponse) => {
    // Store the selected row data
    setSelectedLicenseDetails(record);
    setAutoRenewEnabled(record.agent?.autoRenewEnabled);
    setApplicationModalVisible(true); // Open the modal
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    fetchLicenseDetails(queryText || '', newPageNumber, newPageSize);
  };

  const fetchLicenseDetails = async (
    search?: string,
    page?: number,
    size?: number,
    sort?: object
  ) => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        const response: PaginatedSuccessResponse<LicenseApplicationResponse> | void =
          await LicensesService.getApplicationsForCurrentAgent(
            {
              ...requestBody,
              ...(sort || sortValue || {}),
            },
            bearerToken,
            size || pageSize,
            page || pageNumber,
            search ? search : queryText || '',
            searchType ? searchType : ''
          );
        if (response) {
          setCount(response.totalCount);
          setActiveData(response.data);
          setErrorMessage('');
          setIsLoading(false);
        } else {
          setCount(0);
          setErrorMessage('No producer data available.');
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
      }
      console.error('Error:', error);
      setErrorMessage(
        'An error occurred while fetching admins. Please try again later.'
      );
    }
  };

  const fetchFiltersInfoForFindAll: () => Promise<
    FilterFieldsMetaData[]
  > = async () => {
    try {
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await LicensesService.getFilterInfoForAgentApplication(token);
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string; key: string }) =>
                data.filterType === FilterType.SEARCH && data.key !== 'name'
            ),
            PageConstants.PRODUCER_APPLICATIONS
          )
        );
        return response.data.filter((d: any) => d.key !== 'producerIds');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  useEffect(() => {
    if (Object.keys(requestBody).length) fetchLicenseDetails('', 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setPageNumber(1);
  }, [requestBody]);

  useEffect(() => {
    queryText ? setSearchText(queryText) : setSearchText('');
  }, [queryText]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  return (
    <Card
      style={{
        display: 'block',
        width: '100%',
      }}
    >
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={fetchFiltersInfoForFindAll}
        clearFilters={() => {
          clearFilters();
        }}
        filterPage={PageConstants.PRODUCER_APPLICATIONS}
      />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#0588ca',
          },
        }}
      >
        <div style={{ display: 'flex', columnGap: '10px', marginBottom: 10 }}>
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
            disabled={loading}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-applications'}
              size="middle"
              placeholder="Search Applications by State, LOAs or License Class"
              style={{
                width: '100%',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          <FilterButton
            {...{
              filterInfoLoading,
              activeData,
              requestBody,
              isLoading: loading,
              setIsFilterVisible,
            }}
          />
        </div>
        <RenderFilterChip
          {...{ selectedFilters, removeFilter, clearFilters }}
        />

        <Table
          columns={Applicationcolumns}
          dataSource={activeData}
          pagination={false}
          loading={loading}
          onChange={(pagination, filters, sorter) => {
            const sort = applySort(sorter, fetchLicenseDetails);
            setSortValue(sort);
          }}
        />
        {!isEmpty(activeData) && (
          <Row
            className="pagination"
            style={{ marginTop: '10px' }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={pageNumber}
              pageSize={pageSize}
              onChange={onChange}
              showSizeChanger={false}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              disabled={loading}
            />
          </Row>
        )}
      </ConfigProvider>
      <LicenseModal
        visible={applicationModalVisible}
        setVisible={setApplicationModalVisible}
        agentLicenseDetails={selectedLicenseDetails}
        portal={PortalType.AGENT}
        showTransactionDetails={true}
        autoRenewEnabled={autoRenewEnabled}
      />
    </Card>
  );
}
export default withRoles(Applications, [RoleType.AGENT]);
