import Table, { ColumnsType } from 'antd/es/table';
import {
  multipleOnboardStore,
  producerViewTableContent,
} from '../../../../stores/multipleOnboardStore';
import { useEffect, useState } from 'react';

import { ScbReportService } from '../../../../services/scb-report.service';
import { Skeleton } from 'antd';
import { getNameString } from '../../../../utils/name.utils';
import { useAuth } from '../../../../auth/authProvider';

const ProducerView: React.FC<{
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsBlocking }) => {
  const [pageSize, setPageSize] = useState<number>(100);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    multipleOnboardStore.setMinStep(0);

    setIsBlocking(true);
  }, []);

  const fetchBulkOnboardProducerMinimalDetails = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const response: any = await ScbReportService.getBulkProducerMinimal(
        token,
        multipleOnboardStore.bulkOnboardId
      );
      setData(response.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBulkOnboardProducerMinimalDetails();
  }, []);

  const handleChangePageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const handleChangePageNumber = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const producerViewTableColumns: ColumnsType<producerViewTableContent> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'firstName',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      key: 'npn',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'SSN',
      dataIndex: 'ssn',
      key: 'ssn',
      render: (text, record) => getNameString(text),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => getNameString(text),
    },
  ];

  return loading ? (
    <Skeleton active />
  ) : (
    <Table
      dataSource={data}
      columns={producerViewTableColumns}
      pagination={{
        defaultPageSize: 100,
        showSizeChanger: false,
      }}
    />
  );
};

export default ProducerView;
